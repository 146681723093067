@font-face {
  font-style: normal;
  font-family: Montserrat;
  font-weight: 300;
  src: local('Lato'), url('./public/fonts/Lato-Light.ttf') format("truetype");
}
@font-face {
  font-style: normal;
  font-family: Montserrat;
  font-weight: 400;
  src: local('Montserrat'), url('./public/fonts/Montserrat-Medium.ttf') format("truetype");
}
@font-face {
  font-style: normal;
  font-family: Montserrat;
  font-weight: 700;
  src: local('Montserrat'), url('./public/fonts/Montserrat-Bold.ttf') format("truetype");
}
@font-face {
  font-style: normal;
  font-family: Montserrat;
  font-weight: 900;
  src: local('Montserrat'), url('./public/fonts/Montserrat-ExtraBold.ttf') format("truetype");
}
@font-face {
  font-style: normal;
  font-family: Roboto;
  font-weight: 400;
  src: local('Rooto'), url('./public/fonts/roboto/Roboto-Regular.ttf') format("truetype");
}
@font-face {
  font-style: normal;
  font-family: Roboto;
  font-weight: 500;
  src: local('Rooto'), url('./public/fonts/roboto/Roboto-Medium.ttf') format("truetype");
}
@font-face {
  font-style: normal;
  font-family: Roboto;
  font-weight: 900;
  src: local('Rooto'), url('./public/fonts/roboto/Roboto-Bold.ttf') format("truetype");
}

@font-face {
  font-style: normal;
  font-family: Lato;
  font-weight: 300;
  src: local('Lato'), url('./public/fonts/Lato-Light.ttf') format("truetype");
}
@font-face {
  font-style: normal;
  font-family: Lato;
  font-weight: 400;
  src: local('Lato'), url('./public/fonts/Lato-Regular.ttf') format("truetype");
}
@font-face {
  font-style: normal;
  font-family: Lato;
  font-weight: 700;
  src: local('Lato'), url('./public/fonts/Lato-Bold.ttf') format("truetype");

}
@font-face {
  font-style: normal;
  font-family: Lato;
  font-weight: 900;
  src: local('Lato'), url('./public/fonts/Lato-Black.ttf') format("truetype"); 

}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:#000;
  padding-top: 45px !important;
  height:100%;
  font-family: 'Roboto', sans-serif;
  font-weight: 400 !important;
  font-size: 16px;
  -webkit-font-smoothing: antialiased !important;
  text-rendering: optimizeLegibility !important;
  -moz-osx-font-smoothing: grayscale !important;
  background-color: rgb(243, 244, 245);
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}


@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.cursor{
  cursor: pointer;
}
.fakeLink{
  color:#007bff;
}
.fakeLink:hover{
text-decoration: underline;
}
.upperCase{
  text-transform: uppercase;
}
.capitalize{
  text-transform: capitalize;
}
.strong{
  font-weight: bold;
}

/*NAVBAR*/
.navi-enter {
  opacity: 0.01;
}
.layoutContainer{
  background-color: #fff;
  min-height: 100%;
}
.navi-enter.navi-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.navi-leave {
  opacity: 0.1;
}

.navi-leave.navi-leave-active {
  opacity: 0.01;
  transition: opacity 10ms ease-in;
}
.menuDots{
  font-size:26px;
  margin-top:7px;
  color:rgba(255,255,255,0.5);
}
.menuDots:hover{
  color:rgba(255,255,255,0.8)!important;
}
.navBarBg {
  background-color: #f44336 !important;
  background: #ce2f2f;
  background: linear-gradient(to bottom, #ce2f2f 0%,#f44336 17%,#f44336 38%,#f44336 50%,#ce2f2f 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ce2f2f', endColorstr='#ce2f2f',GradientType=0 );
}
.navbar a {
  font-weight: 700;
  color:rgba(255,255,255,0.5)!important;
  font-family: Roboto!important;
  text-decoration: none;
}
.navbar-nav a {
  font-weight: 700;
  color:rgba(255,255,255,0.5)!important;
  font-family: Roboto!important;
}
.navbar-nav a:hover {
  font-weight: 700;
  color:rgba(255,255,255,0.7)!important;
  font-family: Roboto!important;
}
a.active {
  color:rgba(255,255,255,0.8)!important;
}
/*BURGER MENU*/
/* Position and sizing of burger button */
.bm-burger-button {
  display:none;
  position: fixed;
  width: 0px;
  height: 0px;
  left: 0px;
  top: 0px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}
@media (min-width: 992px) {
  .bm-cross-button{
    left: 20px!important;
  }
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}
.bMenuBtn {
  font-size:30px;
  color:#fff;
  cursor:pointer;

  }

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  transition: all 0.1s ease 0s!important;
  
}

/* General sidebar styles */
.bm-menu {
  background: #fff;
  padding: 2.5em 10px 0;
  font-size: 1em;
  line-height: 1.9em;

}
.hbSelectCont{
  margin-top:-1px;
  margin-left:-5px;
}
.bm-menu select, .hbSelectCont select{
  display: inline-block;
  color:rgba(0, 0, 0, 0.6)!important;
  font-size: 1em;
  font-weight: bold;
  font-family: Roboto!important;
  margin-top: 0.3em;
  border: 0;
  outline: 0;
  background-color: transparent;
  padding:0%;
  
  
}
.hbMenuOption{
  font-weight: bold ;
  margin: 22px !important;
}
/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #fff;
}

/* Wrapper for item list */
.bm-item-list {
  color:#000!important;
  padding: 0.8em;
  text-transform: capitalize;
}
.bm-item-list a, .hbMenuSelectIco{
  font-weight: bold;
  color:rgba(0, 0, 0, 0.6)!important;
}
.bm-item-list a:hover{
 text-decoration: none;
 color:rgba(244, 67, 54, 0.5)!important; 
}
.bm-item-list svg, .hbMenuSelectIco svg{
  font-size:1.3em!important;
  display: inline-block;
  margin-top: -2px;
  margin-right:10px;
}
.hbMenuSelectIco svg{
  margin-top: 10px;
}
/* Individual item */
.bm-item {
  display: inline-block;
  margin-bottom:17px;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

/*DASHBOARD*/
.cardBigText {
  font-weight: bold;
  font-size: 36px;
}
.textBlueGrey {
  color: #484f56;
}
.cardSmallText {
  font-size: 14px;
}
.textGrey {
  color: #b0b3b6;
}

/*MODALS*/
.modal-90h{
  height: 85vh;
}
.modalScrollablleCont{
  position:absolute;
  top:0px;
  bottom: 0px;
  left:0px;
  right:0px;
  overflow-x: hidden;
  overflow-y: auto;
}
/* VIDEO PLAYER */
.videoPlayerCont{
width: 100%;
}
/* sprzedane/kupione box*/
.kupioneListCont{
  max-height:300px;
  overflow-y:auto;
}
/* CHAT */
.chatsMainCont{
  height:90vh;
  overflow:hidden;
}

.chatLeftMainCont{
  height:90vh;

}
.chatRightMainCont{
  height:90vh;
}
.chatListInnerCont{
  position: absolute;
  top:0px;
  bottom:50px;
  left:0px;
  right:0px;
  padding:15px;
  overflow-y:auto;
}
.chatListItem.selected{
  background-color: rgba(244, 67, 54, 0.3);
  font-weight: 600;
}
.chatMobTitle{
  position: relative;
}
.chatMobTitle svg{
  position: absolute;
  top: -5px;
  left: 0px;
  font-size: 1.7em;
  color: #000;
  cursor: pointer;

}
.chatItemCont{
  width:auto!important;
  max-width: 40%;
  padding:10px 20px;
  border-radius: 20px;

  margin-bottom:15px
}
.chatItemCont .displayName{
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 5px;
}
.chatItemCont .reply{
  font-size: 14px;
  margin-bottom: 5px;
}
.chatItemCont .date{
  font-size: 12px;
  margin-bottom: 5px;
  font-weight: 600;
  margin-top:5px
}
.chatItemCont.left{
  float:left;
  background-color: #D32F2F;
  color:#fff;
}
.chatItemCont.left a{
  color:#fff;
  text-decoration: underline;
}
.chatItemCont.right{
  float:right;
  background-color: #f2f2f2;
  color:#000
}

.chatItemCont.banned{
opacity: 0.5;

}
/**WYPLATY*/
.payoutsLeftWrapper{
  height:95vh;
}
.payoutListWrapper{
  overflow:auto;
  min-width: 100%;
  max-width:100%;
}
/*BUTTONS*/

.btn-red {
  background-color: #D32F2F!important;
  color: #fff!important;
  font-size: .8rem;
    padding: 0.85rem 2.13rem;
    margin: 6px;
    border-radius: 2px;
    border: 0;
    transition: .2s ease-out;
    white-space: normal!important;
    cursor: pointer;
    background-color: #dcdcdc;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    text-transform: uppercase;
    word-wrap: break-w
}
.btn-redSm {
  background-color: #D32F2F!important;
  color: #fff!important;
  font-size: .8rem;
    padding: 0.85rem 2.13rem;
    margin: 6px;
    border-radius: 2px;
    border: 0;
    transition: .2s ease-out;
    white-space: normal!important;
    cursor: pointer;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%)!important;
    text-transform: uppercase;
    word-wrap: break-w;
    font-size: .6rem!important;
    padding: 0.5rem 1.6rem!important;
}
/*userNavTab nav-item nav-link active*/
.userNavTab.nav-item .nav-link.active{
  background-color:'#D32F2F'!important;
}
.verPictureOuterCont{
  padding:5px; 
  border:solid 1px rgba(0,0,0,0.1);
}
.dzu-dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 50px!important;
  overflow: auto!important;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  transition: all .15s linear;
  border: 1px solid #d46767!important;
  border-radius: 4px;
}
.dzu-inputLabel{
  font-size: 16px!important;
  font-weight: bold!important;
  color: #d46767!important;
}
.fadeIn {
  animation: fadeInner 1s linear ;
  -webkit-animation: fadeInner 1s linear;
}
.fadeInFast {
  animation: fadeInner 0.5s linear ;
  -webkit-animation: fadeInner 0.5s linear;
}
@-webkit-keyframes fadeInner {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes fadeInner {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

.fadeOut {
  animation: fadeOuter 1s linear ;
  -webkit-animation: fadeOuter 1s linear;
}
.fadeOutFast {
  animation: fadeOuter 0.5s linear ;
  -webkit-animation: fadeOuter 0.5s linear;
}
@-webkit-keyframes fadeOuter {
  0%   { opacity: 1; }
  100% { opacity: 0; }
}
@keyframes fadeOuter {
  0%   { opacity: 1; }
  100% { opacity: 0; }
}
.transparent{
  opacity: 0;
}
.absolute-center{
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
}

@media (max-width: 575px) {
  .border-top {
      border-top: 1px solid rgba(0,0,0,.2);
  }
  .border-left {
      border-left: 1px solid rgba(0,0,0,.2);
  }
  .border-bottom {
      border-bottom: 1px solid rgba(0,0,0,.2);
  }
  .border-right {
      border-right: 1px solid rgba(0,0,0,.2);
  }
  .border-top-0 {
      border-top: none!important;
  }
  .border-left-0 {
      border-left: none!important;
  }
  .border-bottom-0 {
      border-bottom: none!important;
  }
  .border-right-0 {
      border-right: none!important;
  }
}

@media (min-width: 576px) {
  .border-sm-top {
      border-top: 1px solid rgba(0,0,0,.2);
  }
  .border-sm-left {
      border-left: 1px solid rgba(0,0,0,.2);
  }
  .border-sm-bottom {
      border-bottom: 1px solid rgba(0,0,0,.2);
  }
  .border-sm-right {
      border-right: 1px solid rgba(0,0,0,.2);
  }
  .border-sm-top-0 {
      border-top: none!important;
  }
  .border-sm-left-0 {
      border-left: none!important;
  }
  .border-sm-bottom-0 {
      border-bottom: none!important;
  }
  .border-sm-right-0 {
      border-right: none!important;
  }
}

@media (min-width: 768px) {
  .border-md-top {
      border-top: 1px solid rgba(0,0,0,.2);
  }
  .border-md-left {
      border-left: 1px solid rgba(0,0,0,.2);
  }
  .border-md-bottom {
      border-bottom: 1px solid rgba(0,0,0,.2);
  }
  .border-md-right {
      border-right: 1px solid rgba(0,0,0,.2);
  }
  .border-md-top-0 {
      border-top: none!important;
  }
  .border-md-left-0 {
      border-left: none!important;
  }
  .border-md-bottom-0 {
      border-bottom: none!important;
  }
  .border-md-right-0 {
      border-right: none!important;
  }
}

@media (min-width: 992px) {
  .border-lg-top {
      border-top: 1px solid rgba(0,0,0,.2);
  }
  .border-lg-left {
      border-left: 1px solid rgba(0,0,0,.2);
  }
  .border-lg-bottom {
      border-bottom: 1px solid rgba(0,0,0,.2);
  }
  .border-lg-right {
      border-right: 1px solid rgba(0,0,0,.2);
  }
  .border-lg-top-0 {
      border-top: none!important;
  }
  .border-lg-left-0 {
      border-left: none!important;
  }
  .border-lg-bottom-0 {
      border-bottom: none!important;
  }
  .border-lg-right-0 {
      border-right: none!important;
  }
}

@media (min-width: 1200px) {
  .border-xl-top {
      border-top: 1px solid rgba(0,0,0,.2);
  }
  .border-xl-left {
      border-left: 1px solid rgba(0,0,0,.2);
  }
  .border-xl-bottom {
      border-bottom: 1px solid rgba(0,0,0,.2);
  }
  .border-xl-right {
      border-right: 1px solid rgba(0,0,0,.2);
  }
  .border-xl-top-0 {
      border-top: none!important;
  }
  .border-xl-left-0 {
      border-left: none!important;
  }
  .border-xl-bottom-0 {
      border-bottom: none!important;
  }
  .border-xl-right-0 {
      border-right: none!important;
  }
}

.trans30{
  opacity: 0.3;
}

.rdw-editor-main{
  min-height:150px;
}

/*** CHAT NEW ***/
.conversations, .mesge-area {
  display: inline-block;
  position: relative;
  width: 100%;
}
.mesge-area{
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
}

.conversations {
  list-style: outside none none;
  margin-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
  width:100%;
}
.conversations>li {
  display: inline-block;
  margin-bottom: 25px;
  width:100%;
}
.conversations>li>figure, .text-box {
  display: inline-block;
  vertical-align: top;
}
.conversations>li>figure {
  margin-bottom: 0;
}
figure {
  margin: 0 0 1rem;
}
.conversations>li figure img {
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 100%;
  max-width: 25px;
  height: auto;
}
.text-box {
  font-size: 13px;
  margin-left: 10px;
  max-width: 75%;
  position: relative;
}
.text-box>.chatContent {
  background: #dae7f1;
  border-radius: 5px;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 0;
  padding: 8px 7px;
  position: relative;
  color: #838195;
  height: auto;
}
.text-box>span {
  float: right;
  font-size: 11px;
}
.text-box p {
  letter-spacing: .1px;
  line-height: 26px;
  color: #959ab5;
}
.text-box>.chatContent>p span.text {
  color: #000;
}
.text-box>.chatContent p span.text {
  background-color: inherit!important;
  box-shadow: inherit;
  padding: 0;
  margin: 0;
}

.conversations>li.me {
  text-align: right;
}


.conversations>li.you {
  text-align: left;
}
.conversations>li.you  .text-box>.chatContent{
  background:#d32f2f;
  color:#fff!important;
}
.conversations>li.you  .text-box>.chatContent>p span.text{
  color:#fff!important;
}
.mesge-area .mediaBox .mediaItem img {
  max-width: 100px;
  max-height: 100px;
  margin-top: 0;
  margin-right: 0;
}

/* KAMERKI*/
.streamThumb{
  cursor:pointer;
  xborder-radius: 5px;
  overflow: hidden;
}

.streamThumb .dName{
  position:absolute;
  top:10px;
  left:20px;
  font-weight: 500;
  text-transform:capitalize;
  color:#fff;
  padding:5px;
  background-color: rgba(0, 0, 0, .3);
  border-radius:5px;

}
.streamThumb .privBadge{
  position:absolute;
  left:0px;
  bottom: 0px;
  color:#fff;
  padding:5px;
  background-color: rgba(0, 0, 0, .3);
}
.streamThumb .privUser{
  position:absolute;
  bottom:8px;
  right:8px;
  width:50%;
  height:50%;
  xborder-radius: 15px;
  overflow: hidden;
}
.streamThumb  .pudName{
  position:absolute;
  left:20px;
  top:50px;
  color:#fff;
  padding:3px;
  background-color: rgba(0, 0, 0, .3);
  font-style:italic;
  font-weight: 500;
  font-size:0.8rem;
}
.streamWrapper{
  width:100%;

}
.cam2camWrapper{
  position: absolute;
  top:0px;
  left:0px;
  z-index: 1;
  width:150px;
  height:150px;
}
@font-face {
  font-style: normal;
  font-family: Montserrat;
  font-weight: 300;
  src: local('Lato'), url(/static/media/Lato-Light.5b761f2d.ttf) format("truetype");
}
@font-face {
  font-style: normal;
  font-family: Montserrat;
  font-weight: 400;
  src: local('Montserrat'), url(/static/media/Montserrat-Medium.a98626e1.ttf) format("truetype");
}
@font-face {
  font-style: normal;
  font-family: Montserrat;
  font-weight: 700;
  src: local('Montserrat'), url(/static/media/Montserrat-Bold.88932dad.ttf) format("truetype");
}
@font-face {
  font-style: normal;
  font-family: Montserrat;
  font-weight: 900;
  src: local('Montserrat'), url(/static/media/Montserrat-ExtraBold.9bc77c3b.ttf) format("truetype");
}
@font-face {
  font-style: normal;
  font-family: Roboto;
  font-weight: 400;
  src: local('Rooto'), url(/static/media/Roboto-Regular.df7b648c.ttf) format("truetype");
}
@font-face {
  font-style: normal;
  font-family: Roboto;
  font-weight: 500;
  src: local('Rooto'), url(/static/media/Roboto-Medium.894a2ede.ttf) format("truetype");
}
@font-face {
  font-style: normal;
  font-family: Roboto;
  font-weight: 900;
  src: local('Rooto'), url(/static/media/Roboto-Bold.e31fcf18.ttf) format("truetype");
}

@font-face {
  font-style: normal;
  font-family: Lato;
  font-weight: 300;
  src: local('Lato'), url(/static/media/Lato-Light.5b761f2d.ttf) format("truetype");
}
@font-face {
  font-style: normal;
  font-family: Lato;
  font-weight: 400;
  src: local('Lato'), url(/static/media/Lato-Regular.7f690e50.ttf) format("truetype");
}
@font-face {
  font-style: normal;
  font-family: Lato;
  font-weight: 700;
  src: local('Lato'), url(/static/media/Lato-Bold.44dfe8cc.ttf) format("truetype");

}
@font-face {
  font-style: normal;
  font-family: Lato;
  font-weight: 900;
  src: local('Lato'), url(/static/media/Lato-Black.77d35374.ttf) format("truetype"); 

}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:#000;
  padding-top: 45px !important;
  height:100%;
  font-family: 'Roboto', sans-serif;
  font-weight: 400 !important;
  font-size: 16px;
  -webkit-font-smoothing: antialiased !important;
  text-rendering: optimizeLegibility !important;
  -moz-osx-font-smoothing: grayscale !important;
  background-color: rgb(243, 244, 245);
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

